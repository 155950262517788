import React from "react";
import "./modal.css";
import { getBetsFromAccountLogs } from "../../../service/AuthService";
import { useEffect } from "react";
import moment from "moment";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
export default function ClientLegder({ accountLogId,setAccountLogId }) {
  const [header_, setHeader_] = React.useState("Client Ledger");
  const [data, setData] = React.useState([]);
  const getData = async () => {
    const { response } = await getBetsFromAccountLogs(accountLogId);
    let response_ = response;
    let betResponseDTOList = response_.betResponseDTOList;
    let betResponseDTOList_ = [];
    betResponseDTOList.forEach((item) => {
      if (item.back_rate && item.event_type == "Casino") {
        item.type = "back";
        item.rate = item.back_rate;
      } else if (item.lay_rate && item.event_type == "Casino") {
        item.type = "lay";
        item.rate = item.lay_rate;
      }
      if (
        item.back_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "back";
        item.rate = item.back_run;
      } else if (
        item.lay_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "lay";
        item.rate = item.lay_run;
      }
      betResponseDTOList_.push(item);
    });
    response_.betResponseDTOList = betResponseDTOList_;
    let header = `Client Ledger (Total Win Loss: ${response.totalWin}) (Total Soda: ${response.betResponseDTOList.length})`;
    setHeader_(header);
    setData(response_);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {" "}
      <div
        _ngcontent-ng-c1207450300=""
        id="sportSettingModal"
        tabIndex={-1}
        aria-labelledby="yourModalLabel"
        aria-hidden="true"
        style={{ display: accountLogId && "flex" }}
        className="modal fade show"
      >
        <div _ngcontent-ng-c1207450300="" className="modal-dialog">
          <div _ngcontent-ng-c1207450300="" className="modal-content">
            <div
              _ngcontent-ng-c1207450300=""
              className="modal-header bg-primary"
            >
              <h5 _ngcontent-ng-c1207450300="" className="modal-title">
                {header_}
              </h5>
              <button
                _ngcontent-ng-c1207450300=""
                type="button"
                className="close"
              >
                <span
                  onClick={() => {
                    setAccountLogId(null);
                  }}
                  _ngcontent-ng-c1207450300=""
                  aria-hidden="true"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped jambo_table">
                  <thead>
                    <tr _ngcontent-ng-c1207450300="">
                      <td _ngcontent-ng-c1207450300="">Uplevel</td>
                      <td _ngcontent-ng-c1207450300="">Uername</td>
                      <td _ngcontent-ng-c1207450300="">Nation</td>
                      <td _ngcontent-ng-c1207450300="">UserRate</td>
                      <td _ngcontent-ng-c1207450300="">Amount</td>
                      <td _ngcontent-ng-c1207450300="">Win/Loss</td>
                      <td _ngcontent-ng-c1207450300="">PlaceDate</td>
                      <td _ngcontent-ng-c1207450300="">MatchDate</td>
                      <td _ngcontent-ng-c1207450300="">Ip</td>
                      <td _ngcontent-ng-c1207450300="">BrowsersDetails</td>
                    </tr>
                  </thead>
                  <tbody className="table table-bordered mb-0">
                    {data?.betResponseDTOList?.map((item, index) => (
                      <tr className={`${item.type}-f`}>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {item.upline}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {item.userName}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {item.runner_name}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {item.rate}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {item.amount}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-right">
                          <span
                            style={{
                              color: item.loss ? "red" : "green",
                            }}
                            id="style-JaY18"
                            className="style-JaY18"
                          >
                            {item.loss ? "-" + item.loss : item.profit}
                          </span>
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {moment(item.created_date + "Z").format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {moment(item.created_date + "Z").format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </td>
                        <td _ngcontent-ng-c1207450300="" className="text-left">
                          {item.ipAddress}
                        </td>
                        <td _ngcontent-ng-c1207450300="">
                          <Popover preventOverflow={true}>
                            <PopoverTrigger>
                              <Text
                                _hover={{ cursor: "pointer" }}
                                color="blue.500"
                              >
                                Show
                              </Text>
                            </PopoverTrigger>
                            <PopoverContent>
                              {item?.browserDetails}
                            </PopoverContent>
                          </Popover>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
