import React from "react";

export default function Dashboard() {
  return (
    <app-dashboard _nghost-ng-c856661959="" className="ng-star-inserted">
      <div _ngcontent-ng-c856661959="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c856661959="" className="row">
          <div _ngcontent-ng-c856661959="" className="col-sm-12 col-md-6">
            <div
              _ngcontent-ng-c856661959=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c856661959=""
                className="card-header account-detail-head"
              >
                {" "}
                Live Sports Profit
              </div>
              <div
                _ngcontent-ng-c856661959=""
                className="card-body account-stat-body"
                style={{ padding: 0 }}
              >
                <div _ngcontent-ng-c856661959="" className="row">
                  <div _ngcontent-ng-c856661959="" className="col-md-12">
                    <div
                      _ngcontent-ng-c856661959=""
                      id="live"
                      style={{ width: "100%" }}
                    >
                      <div
                        data-highcharts-chart={1}
                        style={{
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          id="highcharts-hkb9hfm-3"
                          dir="ltr"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            width: 358,
                            height: 400,
                            textAlign: "left",
                            lineHeight: "normal",
                            zIndex: 0,
                            WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                            userSelect: "none",
                            touchAction: "manipulation",
                            outline: "none",
                          }}
                          className="highcharts-container "
                        >
                          <svg
                            version="1.1"
                            className="highcharts-root"
                            style={{
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontSize: "1rem",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width={358}
                            height={400}
                            viewBox="0 0 358 400"
                            role="img"
                            aria-label=""
                          >
                            <desc>Created with Highcharts 11.1.0</desc>
                            <defs>
                              <filter id="highcharts-drop-shadow-1">
                                <feDropShadow
                                  dx={1}
                                  dy={1}
                                  floodColor="#000000"
                                  floodOpacity="0.75"
                                  stdDeviation="2.5"
                                />
                              </filter>
                              <clipPath id="highcharts-hkb9hfm-4-">
                                <rect
                                  x={0}
                                  y={0}
                                  width={338}
                                  height={375}
                                  fill="none"
                                />
                              </clipPath>
                            </defs>
                            <rect
                              fill="#ffffff"
                              className="highcharts-background"
                              filter="none"
                              x={0}
                              y={0}
                              width={358}
                              height={400}
                              rx={0}
                              ry={0}
                            />
                            <rect
                              fill="none"
                              className="highcharts-plot-background"
                              x={10}
                              y={10}
                              width={338}
                              height={375}
                              filter="url(#highcharts-drop-shadow-1)"
                            />
                            <rect
                              fill="none"
                              className="highcharts-plot-border"
                              data-z-index={1}
                              stroke="#cccccc"
                              strokeWidth={0}
                              x={10}
                              y={10}
                              width={338}
                              height={375}
                            />
                            <g
                              className="highcharts-series-group"
                              data-z-index={3}
                              filter="none"
                            >
                              <g
                                className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker"
                                data-z-index="0.1"
                                opacity={1}
                                transform="translate(10,10) scale(1 1)"
                                filter="none"
                                style={{ cursor: "pointer" }}
                                clipPath="none"
                              >
                                <path
                                  fill="none"
                                  d="M 169 28.5 A 159 159 0 1 1 168.84100002649993 28.500079499993376 M 169 187.5 A 0 0 0 1 0 169 187.5"
                                  className="highcharts-empty-series"
                                  strokeWidth={1}
                                  stroke="#cccccc"
                                />
                              </g>
                              <g
                                className="highcharts-markers highcharts-series-0 highcharts-pie-series"
                                data-z-index="0.1"
                                opacity={1}
                                transform="translate(10,10) scale(1 1)"
                                clipPath="none"
                              />
                            </g>
                            <text
                              x={179}
                              textAnchor="middle"
                              className="highcharts-title"
                              data-z-index={4}
                              style={{
                                fontSize: "1.2em",
                                color: "rgb(51, 51, 51)",
                                fontWeight: "bold",
                                fill: "rgb(51, 51, 51)",
                              }}
                              y={25}
                            />
                            <text
                              x={179}
                              textAnchor="middle"
                              className="highcharts-subtitle"
                              data-z-index={4}
                              style={{
                                color: "rgb(102, 102, 102)",
                                fontSize: "0.8em",
                                fill: "rgb(102, 102, 102)",
                              }}
                              y={24}
                            />
                            <text
                              x={10}
                              textAnchor="start"
                              className="highcharts-caption"
                              data-z-index={4}
                              style={{
                                color: "rgb(102, 102, 102)",
                                fontSize: "0.8em",
                                fill: "rgb(102, 102, 102)",
                              }}
                              y={397}
                            />
                            <g
                              className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker"
                              data-z-index={6}
                              opacity={1}
                              transform="translate(10,10) scale(1 1)"
                              style={{ cursor: "pointer" }}
                            />
                            <g
                              className="highcharts-legend highcharts-no-tooltip"
                              data-z-index={7}
                              visibility="hidden"
                            >
                              <rect
                                fill="none"
                                className="highcharts-legend-box"
                                rx={0}
                                ry={0}
                                stroke="#999999"
                                strokeWidth={0}
                                filter="none"
                                x={0}
                                y={0}
                                width={8}
                                height={8}
                              />
                              <g data-z-index={1}>
                                <g />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c856661959="" className="col-sm-12 col-md-6">
            <div
              _ngcontent-ng-c856661959=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c856661959=""
                className="card-header account-detail-head"
              >
                {" "}
                Backup Sports Profit
              </div>
              <div
                _ngcontent-ng-c856661959=""
                className="card-body account-stat-body"
                style={{ padding: 0 }}
              >
                <div _ngcontent-ng-c856661959="" className="row">
                  <div _ngcontent-ng-c856661959="" className="col-md-12">
                    <div
                      _ngcontent-ng-c856661959=""
                      id="Backup"
                      style={{ width: "100%" }}
                    >
                      <div
                        data-highcharts-chart={0}
                        style={{
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          id="highcharts-hkb9hfm-0"
                          dir="ltr"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            width: 358,
                            height: 400,
                            textAlign: "left",
                            lineHeight: "normal",
                            zIndex: 0,
                            WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                            userSelect: "none",
                            touchAction: "manipulation",
                            outline: "none",
                          }}
                          className="highcharts-container "
                        >
                          <svg
                            version="1.1"
                            className="highcharts-root"
                            style={{
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontSize: "1rem",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width={358}
                            height={400}
                            viewBox="0 0 358 400"
                            role="img"
                            aria-label=""
                          >
                            <desc>Created with Highcharts 11.1.0</desc>
                            <defs>
                              <filter id="highcharts-drop-shadow-0">
                                <feDropShadow
                                  dx={1}
                                  dy={1}
                                  floodColor="#000000"
                                  floodOpacity="0.75"
                                  stdDeviation="2.5"
                                />
                              </filter>
                              <clipPath id="highcharts-hkb9hfm-2-">
                                <rect
                                  x={0}
                                  y={0}
                                  width={338}
                                  height={375}
                                  fill="none"
                                />
                              </clipPath>
                            </defs>
                            <rect
                              fill="#ffffff"
                              className="highcharts-background"
                              filter="none"
                              x={0}
                              y={0}
                              width={358}
                              height={400}
                              rx={0}
                              ry={0}
                            />
                            <rect
                              fill="none"
                              className="highcharts-plot-background"
                              x={10}
                              y={10}
                              width={338}
                              height={375}
                              filter="url(#highcharts-drop-shadow-0)"
                            />
                            <rect
                              fill="none"
                              className="highcharts-plot-border"
                              data-z-index={1}
                              stroke="#cccccc"
                              strokeWidth={0}
                              x={10}
                              y={10}
                              width={338}
                              height={375}
                            />
                            <g
                              className="highcharts-series-group"
                              data-z-index={3}
                              filter="none"
                            >
                              <g
                                className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker"
                                data-z-index="0.1"
                                opacity={1}
                                transform="translate(10,10) scale(1 1)"
                                filter="none"
                                style={{ cursor: "pointer" }}
                                clipPath="none"
                              >
                                <path
                                  fill="none"
                                  d="M 169 28.5 A 159 159 0 1 1 168.84100002649993 28.500079499993376 M 169 187.5 A 0 0 0 1 0 169 187.5"
                                  className="highcharts-empty-series"
                                  strokeWidth={1}
                                  stroke="#cccccc"
                                />
                              </g>
                              <g
                                className="highcharts-markers highcharts-series-0 highcharts-pie-series"
                                data-z-index="0.1"
                                opacity={1}
                                transform="translate(10,10) scale(1 1)"
                                clipPath="none"
                              />
                            </g>
                            <text
                              x={179}
                              textAnchor="middle"
                              className="highcharts-title"
                              data-z-index={4}
                              style={{
                                fontSize: "1.2em",
                                color: "rgb(51, 51, 51)",
                                fontWeight: "bold",
                                fill: "rgb(51, 51, 51)",
                              }}
                              y={25}
                            />
                            <text
                              x={179}
                              textAnchor="middle"
                              className="highcharts-subtitle"
                              data-z-index={4}
                              style={{
                                color: "rgb(102, 102, 102)",
                                fontSize: "0.8em",
                                fill: "rgb(102, 102, 102)",
                              }}
                              y={24}
                            />
                            <text
                              x={10}
                              textAnchor="start"
                              className="highcharts-caption"
                              data-z-index={4}
                              style={{
                                color: "rgb(102, 102, 102)",
                                fontSize: "0.8em",
                                fill: "rgb(102, 102, 102)",
                              }}
                              y={397}
                            />
                            <g
                              className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker"
                              data-z-index={6}
                              opacity={1}
                              transform="translate(10,10) scale(1 1)"
                              style={{ cursor: "pointer" }}
                            />
                            <g
                              className="highcharts-legend highcharts-no-tooltip"
                              data-z-index={7}
                              visibility="hidden"
                            >
                              <rect
                                fill="none"
                                className="highcharts-legend-box"
                                rx={0}
                                ry={0}
                                stroke="#999999"
                                strokeWidth={0}
                                filter="none"
                                x={0}
                                y={0}
                                width={8}
                                height={8}
                              />
                              <g data-z-index={1}>
                                <g />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-dashboard>
  );
}
