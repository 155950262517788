import { Switch, Td, Text, Toast, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomDataModal from "../../../components/UI/CustomDataModal";
import CustomTable from "../../../components/UI/Table";
import {
  betAndFancyLocksCricketAPI,
  getBetLockUsersCricketAPI,
} from "../../../service/cricket";
import {
  betAndFancyLocksSoccerAPI,
  betAndFancyLocksTennisAPI,
  getBetLockUsersSoccerAPI,
  getBetLockUsersTennisAPI,
} from "../../../service/soccer&tennis";

export default function BetsLock({ eventId, isOpen, onClose, gameType }) {
  const [userBook, setUserBook] = React.useState({});
  const toast = useToast();
  const [rows, setRows] = React.useState([]);
  const getBetLockUsers = async () => {
    var response_;
    if (gameType == "cricket") {
      const { response } = await getBetLockUsersCricketAPI(eventId);
      response_ = response;
    } else if (gameType == "soccer") {
      const { response } = await getBetLockUsersSoccerAPI(eventId);
      response_ = response;
    } else {
      const { response } = await getBetLockUsersTennisAPI(eventId);
      response_ = response;
    }
    setRows(response_?.data);
    setUserBook(response_?.data);
  };
  const columns = [
    {
      name: "SR.No",
      id: "userId",
    },
    {
      name: "User",
      id: "userName",
    },
    {
      name: "Checked",
      id: isOpen == "fancylockuserwise" ? "fancyLock" : "betLock",
      element: (row) => (
        <Td>
          <Switch
            defaultChecked={
              isOpen == "fancylockuserwise" ? row.fancyLock : row.betLock
            }
            onChange={(e) =>
              changeBetLockStatus(row.userId, e.target.checked, rows)
            }
          />
        </Td>
      ),
    },
  ];
  useEffect(() => {
    if (eventId) {
      getBetLockUsers();
    }
  }, [eventId]);
  const changeBetLockStatus = async (userId, value, rows_) => {
    let payload = {};

    if (isOpen == "fancylockuserwise") {
      if (value) {
        payload.fancyLockedUsers = rows
          .filter((row) => row.fancyLock)
          .map((row) => row.userId);
        payload.fancyLockedUsers.push(userId);
        payload.isAdd = true;
      } else {
        payload.fancyLockedUsers = rows
          .filter((row) => !row.fancyLock)
          .map((row) => row.userId);
        payload.fancyLockedUsers.push(userId);
        payload.isAdd = false;
      }
    } else {
      if (value) {
        payload.betLockedUsers = rows
          .filter((row) => row.betLock)
          .map((row) => row.userId);
        payload.betLockedUsers.push(userId);
        payload.isAdd = true;
      } else {
        payload.betLockedUsers = rows
          .filter((row) => !row.betLock)
          .map((row) => row.userId);
        payload.betLockedUsers.push(userId);
        payload.isAdd = false;
      }
    }
    payload.cricketId = eventId;

    var code;
    if (gameType == "cricket") {
      const { response, code: code_ } = await betAndFancyLocksCricketAPI(
        payload
      );
      code = code_;
    } else if (gameType == "soccer") {
      payload.gameId = eventId;
      const { response, code: code_ } = await betAndFancyLocksSoccerAPI(
        payload
      );
      code = code_;
    } else {
      payload.gameId = eventId;
      const { response, code: code_ } = await betAndFancyLocksTennisAPI(
        payload
      );
      code = code_;
    }
    if (code == 200) {
      toast({
        title: "Success",
        description: "Bet Lock Status Changed",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    }
  };
  return (
    <CustomDataModal
      header={isOpen == "fancylockuserwise" ? "BookMaker Book" : "User Book"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <CustomTable columns={columns} rows={rows} showPagination={true} />
    </CustomDataModal>
  );
}
