import { Box, FormLabel, Input, Text } from "@chakra-ui/react";
import React from "react";

export default function CustomInput({ label, ...rest }) {
  let error = rest.error;
  let isValid = false;
  if (typeof error === "object") {
    isValid = error;
  }
  return (
    <Box>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Input
        borderColor={
          isValid
            ? isValid.isError
              ? "red"
              : "green"
            : rest.error
            ? "red"
            : "gray.300"
        }
        errorBorderColor="red"
        size="sm"
        {...rest}
      />
      {rest.error && (
        <Text
          fontSize="xs"
          color={
            isValid
              ? isValid.isError
                ? "red"
                : "green"
              : rest.error
              ? "red"
              : "gray.300"
          }
        >
          {isValid ? isValid.message : rest.error}
        </Text>
      )}
    </Box>
  );
}
