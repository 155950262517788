import { Box, Switch, Text } from "@chakra-ui/react";
import React from "react";

export default function CustomTable({
  rows,
  columns,
  showPagination,
  backLay,
  rowColored,
}) {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);

  React.useEffect(() => {
    if (rows) {
      setRowsPerPageOptions([5, 10, 20, 50, 100]);
      setRowsPerPage(50);
      setTotalPages(Math.ceil(rows.length / 5));
    }
  }, [rows, rowsPerPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <table className="table" variant="simple">
      <thead>
        <tr>
          {columns.map((column) => (
            <th w="max-content">{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows ? (
          rows
            .slice(
              currentPage * rowsPerPage,
              currentPage * rowsPerPage + rowsPerPage
            )
            .map((row) => (
              <tr
                width={columns.length * 100}
                bgColor={rowColored ? (row?.back ? "#72bbef" : "#faa9ba") : ""}
              >
                {columns.map((column) =>
                  column.element ? (
                    column.element(row)
                  ) : (
                    <td>
                      {typeof row[column.id] == "string" ||
                      typeof row[column.id] == "number" ? (
                        <Text fontSize="sm">{row[column.id]}</Text>
                      ) : typeof row[column.id] == "boolean" ? (
                        <Switch
                          size="sm"
                          defaultChecked={
                            column.id == "isBetLocked"
                              ? !row[column.id]
                              : row[column.id]
                          }
                          isDisabled
                        />
                      ) : null}
                    </td>
                  )
                )}
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={columns.length}>
              <Box textAlign="center">
                <Text>No data</Text>
              </Box>
            </td>
          </tr>
        )}
      </tbody>
      {showPagination && (
        <tfoot>
          <tr>
            <th colSpan={columns.length}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Text>Showing Page {currentPage}</Text>
                <Box>
                  <button
                    style={{
                      margin: 5,
                    }}
                    className="btn"
                  >
                    <Text onClick={prevPage}>Prev</Text>
                  </button>
                  <button className="btn">
                    <Text onClick={nextPage}>Next</Text>
                  </button>
                </Box>
              </Box>
              {/* <Box display="flex" justifyContent="space-between">
                  <Text>Rows per page</Text>
                  <Box>
                    {rowsPerPageOptions.map((option) => (
                      <button
                        style={{
                          margin: 5,
                        }}
                        className="btn"
                      >
                        <Text onClick={() => setRowsPerPage(option)}>
                          {option}
                        </Text>
                      </button>
                    ))}
                  </Box>
                </Box> */}
            </th>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
