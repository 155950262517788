const ExportCSV = ({ data, fileName }) => {
  const downloadCSV = () => {
    // Convert the data array into a CSV string
    const csvString = [
      Object.keys(data[0]), // Specify your headers here
      ...data.map((item) => Object.values(item)), // Map your data fields accordingly
    ]
      .map((row) => row.join(","))
      .join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv" });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "download.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <button
      className="dt-button buttons-excel buttons-html5"
      tabIndex={0}
      aria-controls="clientListTable"
      type="button"
      onClick={downloadCSV}
    >
      Export CSV
    </button>
  );
};

export default ExportCSV;
