import React, { useEffect } from "react";
import {
  ClientWithdrawAndDepositAPI,
  getClientAPI,
} from "../../service/UserService";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function Banking() {
  const [clientData, setClientData] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const { user } = useParams();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [size, setSize] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);
  const [payload, setPayload] = React.useState({});
  const [transactionPassword, setTransactionPassword] = React.useState("");
  const getClient = async () => {
    const { response } = await getClientAPI(search, currentPage, size, "");
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS =
        response_.childrenUserResponseDTOSPage.content.sort(
          (a, b) => a.uid - b.uid
        );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }

    setClientData(
      response_.childrenUserResponseDTOSPage?.content.filter(
        (item) => item.userType == user
      )
    );
  };
  const handleSubmit = async () => {
    let users = Object.keys(payload);
    let payload_ = [];
    users.forEach((item) => {
      payload_.push({
        uid: item,
        payload: {
          balance: payload[item].amount,
          transactionPassword: transactionPassword,
          remarks: payload[item].remark,
          transactionType: payload[item].type,
        },
      });
    });
    let result = Promise.all(
      payload_.map(async (item) => {
        const { response, code } = await ClientWithdrawAndDepositAPI(
          item.uid,
          item.payload
        );
        if (code != 200) {
          toast.error(response);
        }
      })
    );
    result.then((res) => {
      getClient();
      setPayload({});
    });
   
  };
  useEffect(() => {
    getClient();
  }, []);
  return (
    <app-user-banking _nghost-ng-c2988304861="" className="ng-star-inserted">
      <div _ngcontent-ng-c2988304861="" className="animated fadeIn">
        {/**/}
        <div _ngcontent-ng-c2988304861="" className="row">
          <div _ngcontent-ng-c2988304861="" className="col-md-12">
            <div
              _ngcontent-ng-c2988304861=""
              className="card my-exchange-head card-accent-primary"
            >
              <div _ngcontent-ng-c2988304861="" className="card-body">
                <div
                  _ngcontent-ng-c2988304861=""
                  className="table-responsive tbl-banking"
                >
                  <div
                    id="DataTables_Table_10_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_10_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="DataTables_Table_10_length"
                          aria-controls="DataTables_Table_10"
                          className=""
                          onChange={(e) => {
                            setSize(e.target.value);
                            getClient();
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="DataTables_Table_10_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder=""
                          aria-controls="DataTables_Table_10"
                        />
                      </label>
                    </div>
                    <table
                      _ngcontent-ng-c2988304861=""
                      datatable=""
                      role="table"
                      aria-busy="false"
                      aria-colcount={6}
                      className="table table-bordered table-hover dataTable no-footer"
                      id="DataTables_Table_10"
                      aria-describedby="DataTables_Table_10_info"
                    >
                      <thead _ngcontent-ng-c2988304861="" role="rowgroup">
                        <tr _ngcontent-ng-c2988304861="" role="row">
                          <th
                            _ngcontent-ng-c2988304861=""
                            className="sorting sorting_desc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_10"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="descending"
                            aria-label="UID: activate to sort column ascending"
                          >
                            UID
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_10"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Balance: activate to sort column ascending"
                          >
                            Balance
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_10"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Available D / W: activate to sort column ascending"
                          >
                            Available D / W
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Exposure"
                          >
                            Exposure
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_10"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Credit Reference: activate to sort column ascending"
                          >
                            Credit Reference
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Reference P/L"
                          >
                            Reference P/L
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            style={{ minWidth: 200 }}
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Deposit/Withdraw"
                          >
                            Deposit/Withdraw
                          </th>
                          <th
                            _ngcontent-ng-c2988304861=""
                            style={{ minWidth: 100 }}
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Remark"
                          >
                            Remark
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="odd">
                          <td
                            valign="top"
                            colSpan={12}
                            className="dataTables_empty"
                          >
                            No matching records found
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        _ngcontent-ng-c2988304861=""
                        className="ng-star-inserted"
                      >
                        {clientData.map((item, index) => (
                          <tr
                            _ngcontent-ng-c2988304861=""
                            className="ng-star-inserted"
                          >
                            <td _ngcontent-ng-c2988304861="">
                              <strong _ngcontent-ng-c2988304861="">
                                {item.username}
                              </strong>
                            </td>
                            <td _ngcontent-ng-c2988304861="">
                              <strong _ngcontent-ng-c2988304861="">
                                {item.balance}
                              </strong>
                            </td>
                            <td _ngcontent-ng-c2988304861="">
                              <strong _ngcontent-ng-c2988304861="">
                                {item?.userType?.toLowerCase() != "client"
                                  ? item?.calculatedBalance?.toFixed(2)
                                  : item?.balance?.toFixed(2)}
                              </strong>
                            </td>
                            <td _ngcontent-ng-c2988304861="" className="cl-red">
                              <strong _ngcontent-ng-c2988304861="">
                                ({item?.liability?.toFixed(2)})
                              </strong>
                            </td>
                            <td _ngcontent-ng-c2988304861="">
                              <a
                                _ngcontent-ng-c2988304861=""
                                data-bs-toggle="modal"
                                data-bs-target="#EditRefereceModal"
                              >
                                <strong _ngcontent-ng-c2988304861="">
                                  {item?.creditReference?.toFixed(2)}
                                </strong>
                                <i
                                  _ngcontent-ng-c2988304861=""
                                  className="fa fa-pencil-square-o m-l-10"
                                />
                              </a>
                            </td>
                            <td
                              _ngcontent-ng-c2988304861=""
                              className={`${
                                item?.ownPl > 0
                                  ? "profit cl-green"
                                  : "loss cl-red"
                              } cl-black `}
                            >
                              <strong _ngcontent-ng-c2988304861="">
                                {item?.ownPl}
                              </strong>
                            </td>
                            <td
                              _ngcontent-ng-c2988304861=""
                              className="tbl-cell-depst d-flex"
                            >
                              <button
                                _ngcontent-ng-c2988304861=""
                                onClick={() => {
                                  setPayload({
                                    ...payload,
                                    [item?.uid]: {
                                      ...payload[item?.uid],
                                      type: "DEPOSIT",
                                    },
                                  });
                                }}
                                className={`btn btn-brand ${
                                  payload[item?.uid]?.type === "DEPOSIT"
                                    ? "active"
                                    : ""
                                } text bt-deposit btn-secondary`}
                              >
                                <span
                                  _ngcontent-ng-c2988304861=""
                                  className="text-white"
                                >
                                  D
                                </span>
                              </button>
                              <button
                                _ngcontent-ng-c2988304861=""
                                onClick={() => {
                                  setPayload({
                                    ...payload,
                                    [item?.uid]: {
                                      ...payload[item?.uid],
                                      type: "WITHDRAW",
                                    },
                                  });
                                }}
                                className={`btn btn-brand ${
                                  payload[item?.uid]?.type === "WITHDRAW"
                                    ? "active"
                                    : ""
                                } text bt-withdraw btn-secondary`}
                              >
                                <span
                                  _ngcontent-ng-c2988304861=""
                                  className="text-white"
                                >
                                  W
                                </span>
                              </button>
                              {/**/}
                              <input
                                _ngcontent-ng-c2988304861=""
                                placeholder={0}
                                type="text"
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    [item?.uid]: {
                                      ...payload[item?.uid],
                                      amount: e.target.value,
                                    },
                                  });
                                }}
                                value={payload[item?.uid]?.amount}
                                className="form-control inpt-dpst ng-star-inserted"
                                id="depisutamoutn0"
                              />
                              {/**/}
                              <button
                                _ngcontent-ng-c2988304861=""
                                className="btn btn-brand btn-primary bt-full text"
                                disabled=""
                                onClick={() => {
                                  setPayload({
                                    ...payload,
                                    [item?.uid]: {
                                      ...payload[item?.uid],
                                      amount: item?.balance - item?.liability,
                                    },
                                  });
                                }}
                              >
                                <span _ngcontent-ng-c2988304861="">
                                  <strong _ngcontent-ng-c2988304861="">
                                    Full
                                  </strong>
                                </span>
                              </button>
                            </td>
                            <td _ngcontent-ng-c2988304861="">
                              {/**/}
                              <input
                                _ngcontent-ng-c2988304861=""
                                placeholder="Remark"
                                type="text"
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    [item?.uid]: {
                                      ...payload[item?.uid],
                                      remark: e.target.value,
                                    },
                                  });
                                }}
                                value={payload[item?.uid]?.remark}
                                className="form-control ng-star-inserted"
                                id="Remark0"
                              />
                              {/**/}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/**/}
                      {/**/}
                    </table>
                    <div
                      className="dataTables_info"
                      id="DataTables_Table_10_info"
                      role="status"
                      aria-live="polite"
                    >
                      {`Showing ${currentPage * size + 1} to ${
                        currentPage * size + clientData.length
                      } of ${totalElements} entries`}
                    </div>
                    <div
                      className="dataTables_paginate paging_full_numbers"
                      id="DataTables_Table_10_paginate"
                    >
                      <a
                        className="paginate_button first disabled"
                        aria-controls="DataTables_Table_10"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="first"
                        tabIndex={-1}
                        onClick={
                          currentPage != 0
                            ? () => {
                                setCurrentPage(0);
                                getClient();
                              }
                            : () => {}
                        }
                        id="DataTables_Table_10_first"
                      >
                        First
                      </a>
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="DataTables_Table_10"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabIndex={-1}
                        onClick={
                          currentPage != 0
                            ? () => {
                                setCurrentPage(currentPage - 1);
                                getClient();
                              }
                            : () => {}
                        }
                        id="DataTables_Table_10_previous"
                      >
                        Previous
                      </a>
                      <span>
                        <a
                          className="paginate_button current"
                          aria-controls="DataTables_Table_10"
                          role="link"
                          aria-current="page"
                          data-dt-idx={0}
                          tabIndex={0}
                        >
                          {currentPage + 1}
                        </a>
                        <a
                          className="paginate_button "
                          aria-controls="DataTables_Table_10"
                          role="link"
                          data-dt-idx={1}
                          tabIndex={0}
                        >
                          {currentPage + 2}
                        </a>
                      </span>
                      <a
                        className="paginate_button next"
                        aria-controls="DataTables_Table_10"
                        role="link"
                        data-dt-idx="next"
                        tabIndex={0}
                        onClick={
                          currentPage != totalPages - 1
                            ? () => {
                                setCurrentPage(currentPage + 1);
                                getClient();
                              }
                            : () => {}
                        }
                        id="DataTables_Table_10_next"
                      >
                        Next
                      </a>
                      <a
                        className="paginate_button last"
                        aria-controls="DataTables_Table_10"
                        role="link"
                        data-dt-idx="last"
                        tabIndex={0}
                        onClick={
                          currentPage != totalPages - 1
                            ? () => {
                                setCurrentPage(totalPages - 1);
                                getClient();
                              }
                            : () => {}
                        }
                        id="DataTables_Table_10_last"
                      >
                        Last
                      </a>
                    </div>
                  </div>
                  {/**/}
                </div>
                <div _ngcontent-ng-c2988304861="" className="divider" />
                <div
                  _ngcontent-ng-c2988304861=""
                  className="row align-items-center"
                >
                  <div
                    _ngcontent-ng-c2988304861=""
                    className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"
                  >
                    <button
                      _ngcontent-ng-c2988304861=""
                      type="button"
                      onClick={() => {
                        setPayload({});
                      }}
                      className="btn btn-block btn-danger"
                    >
                      Clear All
                    </button>
                  </div>
                  <div
                    _ngcontent-ng-c2988304861=""
                    className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"
                  >
                    <input
                      _ngcontent-ng-c2988304861=""
                      autoComplete="new-password"
                      name="paymentPass"
                      onChange={(e) => {
                        setTransactionPassword(e.target.value);
                      }}
                      value={transactionPassword}
                      placeholder="Password.."
                      type="password"
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c2988304861=""
                    className="col-12 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"
                  >
                    <button
                      _ngcontent-ng-c2988304861=""
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-block btn-primary"
                    >
                      Submit Payment
                    </button>
                  </div>
                  <div
                    _ngcontent-ng-c2988304861=""
                    className="col-md-3 col-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c2988304861=""
        id="EditRefereceModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        className="modal fade"
      >
        <div _ngcontent-ng-c2988304861="" className="modal-dialog">
          <div _ngcontent-ng-c2988304861="" className="modal-content">
            <div
              _ngcontent-ng-c2988304861=""
              className="modal-header bg-primary"
            >
              <h5 _ngcontent-ng-c2988304861="" className="modal-title">
                Edit Credit Reference -
              </h5>
              <button
                _ngcontent-ng-c2988304861=""
                type="button"
                className="close"
              >
                <span
                  _ngcontent-ng-c2988304861=""
                  aria-hidden="true"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  ×
                </span>
              </button>
            </div>
            <div _ngcontent-ng-c2988304861="" className="modal-body">
              <div _ngcontent-ng-c2988304861="" className="ms-3">
                <div _ngcontent-ng-c2988304861="" className="form-group row">
                  <div _ngcontent-ng-c2988304861="" className="col-md-2">
                    Current
                  </div>
                  <div _ngcontent-ng-c2988304861="" className="col-md-8">
                    <strong _ngcontent-ng-c2988304861="" />
                  </div>
                </div>
                <div _ngcontent-ng-c2988304861="" className="form-group row">
                  <div
                    _ngcontent-ng-c2988304861=""
                    className="col-md-2 col-form-label"
                  >
                    New
                  </div>
                  <div _ngcontent-ng-c2988304861="" className="col-md-8">
                    <input
                      _ngcontent-ng-c2988304861=""
                      type="number"
                      oninput="validity.valid||(value='');"
                      min={0}
                      max={999999999999}
                      required=""
                      name="newLimit"
                      className="text-right form-control maxlength12 ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c2988304861="" className="form-group row">
                  <div
                    _ngcontent-ng-c2988304861=""
                    className="col-md-2 col-form-label"
                  >
                    Password
                  </div>
                  <div _ngcontent-ng-c2988304861="" className="col-md-8">
                    <input
                      _ngcontent-ng-c2988304861=""
                      type="password"
                      id="masterpassword2"
                      name="masterpassword2"
                      required=""
                      className="form-control ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-ng-c2988304861="" className="modal-footer">
              <button
                _ngcontent-ng-c2988304861=""
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                Submit
              </button>
              <button
                _ngcontent-ng-c2988304861=""
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-default"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-user-banking>
  );
}
