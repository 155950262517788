import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ChangeClientGlobalSettingAPI,
  ChangeClientLimitAPI,
  ChangeClientPasswordAPI,
  getGlobalSettingsAPI,
  getUserDataAPI,
} from "../service/UserService";
import toast from "react-hot-toast";
import {
  changePasswordAPI,
  getUserByUsernameAPI,
} from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import CurrentBets from "./Reports/CurrentBets";
import BetHistory from "./Reports/BetHistory";
import ProfitLoss from "./Reports/ProfitLoss";
import AccountStatement from "./Reports/AccountStatement";
import ActivityLog from "./Client/Activity";
import ProfitLossUserWise from "./Reports/ProfitLossUserWise";

export default function Profile() {
  const userData = useSelector((state) => state.account.userData);
  const { state: fromPreviousPage } = useLocation();
  const location = useLocation();
  const [state, setState] = useState(
    fromPreviousPage ? fromPreviousPage : userData
  );
  const { page } = useParams();
  const [user, setUser] = useState({});
  const getUserProfile = async () => {
    const { response, code, status } = await getGlobalSettingsAPI(state?.uid);
    if (code == 200) {
      setUser(response);
    }
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  const [payload, setPayload] = useState({
    ...user,
    showTransactionPassword: false,
    showPassword: false,
    showConfirmPassword: false,
  });
  const changeGlobalSettings = async () => {
    const { response, code, status } = await ChangeClientGlobalSettingAPI(
      {
        commissionInCricketBookmaker: payload?.commissionInCricketBookmaker
          ? payload?.commissionInCricketBookmaker
          : payload?.commissionInCricket,
        commissionInCricketOdds: payload?.commissionInCricketOdds
          ? payload?.commissionInCricketOdds
          : payload?.commissionInCricket,
        commissionInCricketSession: payload?.commissionInCricketSession
          ? payload?.commissionInCricketSession
          : payload?.commissionInCricket,
        commissionInFootballBookmaker: payload?.commissionInFootballBookmaker
          ? payload?.commissionInFootballBookmaker
          : payload?.commissionInCricket,
        commissionInFootballOdds: payload?.commissionInFootballOdds
          ? payload?.commissionInFootballOdds
          : payload?.commissionInCricket,
        commissionInFootballSession: payload?.commissionInFootballSession
          ? payload?.commissionInFootballSession
          : payload?.commissionInCricket,
        commissionInTennisBookmaker: payload?.commissionInTennisBookmaker
          ? payload?.commissionInTennisBookmaker
          : payload?.commissionInCricket,
        commissionInTennisOdds: payload?.commissionInTennisOdds
          ? payload?.commissionInTennisOdds
          : payload?.commissionInCricket,
        commissionInTennisSession: payload?.commissionInTennisSession
          ? payload?.commissionInTennisSession
          : payload?.commissionInCricket,
        transactionPassword: payload?.transactionPassword,
      },
      state.uid
    );
    if (code == 200) {
      toast.success("Update Successfully");
      window.location.reload();
    } else {
      toast.error(response);
    }
  };

  const changeExposureLimit = async () => {
    const { response, code, status } = await ChangeClientLimitAPI(
      {
        newLimit: payload.exposureLimit,
        transactionPassword: payload.transactionPassword,
        oldLimit: state?.exposureLimit,
        limitType: "EXPOSURE_LIMIT",
      },
      state.uid
    );
    if (code == 200) {
      toast.success("Update Successfully");
      window.location.reload();
    } else {
      toast.error(response);
    }
  };

  const changePassword = async () => {
    if (payload.newPassword !== payload.confirmPassword)
      return toast.error("Password and Confirm Password must be same");
    if (payload?.newPassword.length < 5)
      return toast.error("Password must be at least 5 characters long ");
    if (!payload.transactionPassword)
      return toast.error("Please enter master password");

    if (payload?.newPassword !== payload?.confirmPassword)
      return toast.error("Password and Confirm Password must be same");

    const apiPayload = {
      newPassword: Encrypt(payload.newPassword),
      transactionPassword: payload.transactionPassword,
      resetRequired: true,
    };
    if (state?.username == userData?.username) {
      const { response, code, status } = await changePasswordAPI({
        ...apiPayload,
        password: Encrypt(payload.transactionPassword),
        uid: state?.id,
      });
      if (code == 200) {
        toast.success(status);
        setPayload({});
        window.location.reload();
      } else {
        toast.error(response);
      }
    } else {
      const { response, code, status } = await ChangeClientPasswordAPI(
        apiPayload,
        state.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        window.location.reload();
      } else {
        toast.error(response);
      }
    }
  };
  const navigate = useNavigate();

  const pageMap = {
    profile: (
      <app-profile _nghost-ng-c635366232="" className="ng-star-inserted">
        <div
          _ngcontent-ng-c635366232=""
          className="animated fadeIn divider-top"
        >
          <div _ngcontent-ng-c635366232="" className="card card-accent-primary">
            <div
              _ngcontent-ng-c635366232=""
              className="card-header account-detail-head"
            >
              {" "}
              Account Details{" "}
            </div>
            <div
              _ngcontent-ng-c635366232=""
              className="card-body account-detail-body"
            >
              <div _ngcontent-ng-c635366232="" className="bd-example">
                <dl _ngcontent-ng-c635366232="" className="row">
                  <dt _ngcontent-ng-c635366232="" className="col-sm-3">
                    Name
                  </dt>
                  <dd _ngcontent-ng-c635366232="" className="col-sm-9">
                    {state?.username}
                  </dd>
                  <dt _ngcontent-ng-c635366232="" className="col-sm-3">
                    Commission
                  </dt>
                  <dd _ngcontent-ng-c635366232="" className="col-sm-9">
                    {user?.commissionInCricketOdds}
                    <a
                      _ngcontent-ng-c635366232=""
                      data-bs-toggle="modal"
                      data-bs-target="#UpdateCommissionModal"
                      className="ng-star-inserted"
                    >
                      <i
                        _ngcontent-ng-c635366232=""
                        className="fa fa-pencil-square-o fa-lg ms-2"
                      />
                    </a>
                    {/**/}
                  </dd>
                  <dt
                    _ngcontent-ng-c635366232=""
                    className="col-sm-3 ng-star-inserted"
                  >
                    Rolling Commission
                  </dt>
                  {/**/}
                  <dd
                    _ngcontent-ng-c635366232=""
                    className="col-sm-9 ng-star-inserted"
                  >
                    <a
                      _ngcontent-ng-c635366232=""
                      data-bs-toggle="modal"
                      data-bs-target="#modalRoolingCOmmision"
                    >
                      <i
                        _ngcontent-ng-c635366232=""
                        className="fa fa-pencil-square-o fa-lg"
                      />
                    </a>
                    {/**/}
                  </dd>
                  {/**/}
                  {/**/}
                  {/**/}
                  <dt _ngcontent-ng-c635366232="" className="col-sm-3">
                    Currency
                  </dt>
                  <dd _ngcontent-ng-c635366232="" className="col-sm-9">
                    {" "}
                    BAL.
                  </dd>
                  <dt
                    _ngcontent-ng-c635366232=""
                    className="col-sm-3 ng-star-inserted"
                  >
                    Exposure Limit
                  </dt>
                  {/**/}
                  <dd
                    _ngcontent-ng-c635366232=""
                    className="col-sm-9 ng-star-inserted"
                  >
                    <span _ngcontent-ng-c635366232="" className="me-3">
                      {state?.exposureLimit}
                    </span>
                    <a
                      _ngcontent-ng-c635366232=""
                      data-bs-toggle="modal"
                      data-bs-target="#EditRefereceModal"
                      className="ng-star-inserted"
                    >
                      <i
                        _ngcontent-ng-c635366232=""
                        className="fa fa-pencil-square-o fa-lg"
                      />
                    </a>
                    {/**/}
                  </dd>
                  {/**/}
                  {/**/}
                  {/**/}
                  <dt _ngcontent-ng-c635366232="" className="col-sm-3">
                    Mobile Number
                  </dt>
                  <dd _ngcontent-ng-c635366232="" className="col-sm-9">
                    <span _ngcontent-ng-c635366232="" className="me-3">
                      0
                    </span>
                    <a
                      _ngcontent-ng-c635366232=""
                      data-bs-toggle="modal"
                      data-bs-target="#editMobileNoModal"
                      className="ng-star-inserted"
                    >
                      <i
                        _ngcontent-ng-c635366232=""
                        className="fa fa-pencil-square-o fa-lg"
                      />
                    </a>
                    {/**/}
                  </dd>
                  <dt
                    _ngcontent-ng-c635366232=""
                    className="col-sm-3 ng-star-inserted"
                  >
                    Password
                  </dt>
                  {/**/}
                  <dd
                    _ngcontent-ng-c635366232=""
                    className="col-sm-9 ng-star-inserted"
                  >
                    <span _ngcontent-ng-c635366232="" className="me-3">
                      *********
                    </span>
                    <a
                      _ngcontent-ng-c635366232=""
                      data-bs-toggle="modal"
                      data-bs-target="#changePasswordModal"
                    >
                      <i
                        _ngcontent-ng-c635366232=""
                        className="fa fa-pencil-square-o fa-lg"
                      />
                    </a>
                  </dd>
                  {/**/}
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="modalRoolingCOmmision"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  Rolling Commission - {state?.username}
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-body ps-4">
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Fancy
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rfancyCommission"
                        placeholder="Fancy Rolling Commission.."
                        type="text"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            commissionInCricketSession: e.target.value,
                          });
                        }}
                        defaultValue={payload?.commissionInCricketSession}
                        value={payload?.commissionInCricketSession}
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Matka
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rmatkaCommission"
                        placeholder="Matka Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Casino
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rcasinoCommission"
                        placeholder="Casino Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                {/**/}
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Sportbook
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rsportbookCommission"
                        placeholder="Sportbook Rolling Commission.."
                        type="text"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            commissionInCricketOdds: e.target.value,
                          });
                        }}
                        value={payload?.commissionInCricketOdds}
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Bookmaker
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rbookmakerCommission"
                        value={payload?.commissionInCricketBookmaker}
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            commissionInCricketBookmaker: e.target.value,
                          });
                        }}
                        placeholder="Bookmaker Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Virtual Sports
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rbookmakerCommission"
                        placeholder="Virtual Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Password
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <div
                        _ngcontent-ng-c635366232=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c635366232=""
                          name="rate"
                          placeholder="Password.."
                          value={payload?.transactionPassword}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              transactionPassword: e.target.value,
                            });
                          }}
                          required=""
                          className="form-control ng-untouched ng-pristine ng-invalid"
                          type="password"
                        />
                        <a _ngcontent-ng-c635366232="" className="eyebtn">
                          <i
                            _ngcontent-ng-c635366232=""
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-footer">
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-primary"
                  disabled=""
                  onClick={changeGlobalSettings}
                >
                  Submit
                </button>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-default"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="modalRoolingCOmmisionAgent"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  {state?.userType} Rolling Commission - {state?.username}
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-body ps-4">
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Fancy
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rfancyCommission"
                        placeholder="Fancy Rolling Commission.."
                        type="text"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            commissionInCricketSession: e.target.value,
                          });
                        }}
                        value={payload?.commissionInCricketSession}
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Matka
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rmatkaCommission"
                        placeholder="Matka Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Casino
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rcasinoCommission"
                        placeholder="Casino Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                {/**/}
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Sportbook
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rsportbookCommission"
                        placeholder="Sportbook Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Bookmaker
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rbookmakerCommission"
                        placeholder="Bookmaker Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Virtual Sports
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        name="rbookmakerCommission"
                        placeholder="Virtual Rolling Commission.."
                        type="text"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Password
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <div
                        _ngcontent-ng-c635366232=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c635366232=""
                          name="rate"
                          placeholder="Password.."
                          required=""
                          className="form-control ng-untouched ng-pristine ng-invalid"
                          type="password"
                        />
                        <a _ngcontent-ng-c635366232="" className="eyebtn">
                          <i
                            _ngcontent-ng-c635366232=""
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-footer">
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-primary"
                  disabled=""
                >
                  Submit
                </button>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-default"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="EditRefereceModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  Edit Exposure Limit - {state?.username}{" "}
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-body">
                <div _ngcontent-ng-c635366232="" className="ms-3">
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div _ngcontent-ng-c635366232="" className="col-md-2">
                      Current
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <strong _ngcontent-ng-c635366232="">
                        {state?.exposureLimit}
                      </strong>
                    </div>
                  </div>
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      New
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <input
                        _ngcontent-ng-c635366232=""
                        type="number"
                        oninput="validity.valid||(value='');"
                        min={0}
                        max={999999999999}
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            exposureLimit: e.target.value,
                          });
                        }}
                        value={payload?.exposureLimit}
                        required=""
                        name="newLimit"
                        className="text-right form-control maxlength12 ng-untouched ng-pristine ng-invalid"
                      />
                    </div>
                  </div>
                  <div _ngcontent-ng-c635366232="" className="form-group row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-form-label"
                    >
                      Password
                    </div>
                    <div _ngcontent-ng-c635366232="" className="col-md-8">
                      <div
                        _ngcontent-ng-c635366232=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c635366232=""
                          id="masterpassword2"
                          name="masterpassword2"
                          value={payload?.transactionPassword}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              transactionPassword: e.target.value,
                            });
                          }}
                          required=""
                          className="form-control ng-untouched ng-pristine ng-invalid"
                          type="password"
                        />
                        <a _ngcontent-ng-c635366232="" className="eyebtn">
                          <i
                            _ngcontent-ng-c635366232=""
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-footer">
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={changeExposureLimit}
                >
                  Submit
                </button>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-default"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="editMobileNoModal"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  {" "}
                  Change Mobile Number
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-body row">
                <div _ngcontent-ng-c635366232="" className="col-md-6">
                  <div _ngcontent-ng-c635366232="" className="form-group">
                    <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                      Your Password{" "}
                      <span
                        _ngcontent-ng-c635366232=""
                        className="asterisk_input"
                      />
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="d-flex align-items-center position-relative"
                    >
                      <input
                        _ngcontent-ng-c635366232=""
                        name="masterPassword"
                        placeholder="Your Password.."
                        required=""
                        className="form-control ng-untouched ng-pristine ng-invalid"
                        type="password"
                      />
                      <a _ngcontent-ng-c635366232="" className="eyebtn">
                        <i _ngcontent-ng-c635366232="" className="far fa-eye" />
                      </a>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-6">
                  <div _ngcontent-ng-c635366232="" className="form-group">
                    <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                      Mobile Number{" "}
                      <span
                        _ngcontent-ng-c635366232=""
                        className="asterisk_input"
                      />
                    </div>
                    <input
                      _ngcontent-ng-c635366232=""
                      name="mobileNumber"
                      placeholder="Mobile Number"
                      required=""
                      type="text"
                      className="form-control ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-footer">
                <button
                  _ngcontent-ng-c635366232=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  className="btn btn-primary"
                >
                  Yes
                </button>
                <button
                  _ngcontent-ng-c635366232=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  className="btn btn-default"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="changePasswordModal"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  {" "}
                  Change Password
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <form
                _ngcontent-ng-c635366232=""
                noValidate=""
                className="ng-untouched ng-pristine ng-invalid"
              >
                <div _ngcontent-ng-c635366232="" className="modal-body row">
                  <div _ngcontent-ng-c635366232="" className="col-md-6">
                    <div _ngcontent-ng-c635366232="" className="form-group">
                      <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                        Master Password{" "}
                        <span
                          _ngcontent-ng-c635366232=""
                          className="asterisk_input"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c635366232=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          value={payload?.transactionPassword}
                          _ngcontent-ng-c635366232=""
                          formcontrolname="MasterPassword"
                          id="masterpassword3"
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              transactionPassword: e.target.value,
                            });
                          }}
                          name="masterpassword3"
                          required=""
                          className="form-control ng-untouched ng-pristine ng-invalid"
                          type={
                            payload?.showTransactionPassword
                              ? "text"
                              : "password"
                          }
                        />
                        <a _ngcontent-ng-c635366232="" className="eyebtn">
                          <i
                            _ngcontent-ng-c635366232=""
                            className={
                              payload?.showTransactionPassword
                                ? "far fa-eye"
                                : "far fa-eye-slash"
                            }
                            onClick={() => {
                              setPayload({
                                ...payload,
                                showTransactionPassword:
                                  !payload.showTransactionPassword,
                              });
                            }}
                          />
                        </a>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div _ngcontent-ng-c635366232="" className="col-md-6">
                    <div _ngcontent-ng-c635366232="" className="form-group">
                      <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                        New Password{" "}
                        <span
                          _ngcontent-ng-c635366232=""
                          className="asterisk_input"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c635366232=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c635366232=""
                          formcontrolname="password"
                          name="password"
                          required=""
                          value={payload?.newPassword}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              newPassword: e.target.value,
                            });
                          }}
                          autoComplete="off"
                          maxLength={20}
                          className="text-right form-control ng-untouched ng-pristine ng-invalid"
                          type={!payload?.showPassword ? "text" : "password"}
                        />
                        <a _ngcontent-ng-c635366232="" className="eyebtn">
                          <i
                            _ngcontent-ng-c635366232=""
                            className={
                              payload?.showPassword
                                ? "far fa-eye"
                                : "far fa-eye-slash"
                            }
                            onClick={() => {
                              setPayload({
                                ...payload,
                                showPassword: !payload.showPassword,
                              });
                            }}
                          />
                        </a>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div _ngcontent-ng-c635366232="" className="col-md-6">
                    <div _ngcontent-ng-c635366232="" className="form-group">
                      <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                        Confirm Password{" "}
                        <span
                          _ngcontent-ng-c635366232=""
                          className="asterisk_input"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c635366232=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c635366232=""
                          formcontrolname="confirmPassword"
                          maxLength={20}
                          name="confirmpassword"
                          value={payload?.confirmPassword}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              confirmPassword: e.target.value,
                            });
                          }}
                          required=""
                          className="form-control ng-untouched ng-pristine ng-invalid"
                          type={
                            !payload?.showConfirmPassword ? "text" : "password"
                          }
                        />
                        <a _ngcontent-ng-c635366232="" className="eyebtn">
                          <i
                            _ngcontent-ng-c635366232=""
                            className={
                              !payload?.showConfirmPassword
                                ? "far fa-eye"
                                : "far fa-eye-slash"
                            }
                            onClick={() => {
                              setPayload({
                                ...payload,
                                showConfirmPassword:
                                  !payload.showConfirmPassword,
                              });
                            }}
                          />
                        </a>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
              </form>
              <div _ngcontent-ng-c635366232="" className="modal-footer">
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="btn btn-primary"
                  disabled=""
                  onClick={changePassword}
                >
                  Confirm
                </button>
                <button
                  _ngcontent-ng-c635366232=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  className="btn btn-default"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="RoolingMixedModal"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  {" "}
                  Rooling Commission
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-body">
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="view-rolling row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-3 col-form-label"
                    >
                      Fancy
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-8 col-9 col-form-label"
                    >
                      <span _ngcontent-ng-c635366232="">0</span>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="view-rolling row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-3 col-form-label"
                    >
                      Matka
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-8 col-9 col-form-label"
                    >
                      <span _ngcontent-ng-c635366232="">0</span>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="view-rolling row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-3 col-form-label"
                    >
                      Casino
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-8 col-9 col-form-label"
                    >
                      <span _ngcontent-ng-c635366232="">0</span>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="view-rolling row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-3 col-form-label"
                    >
                      Binary
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-8 col-9 col-form-label"
                    >
                      <span _ngcontent-ng-c635366232="" />
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="view-rolling row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-3 col-form-label"
                    >
                      Sportbook
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-8 col-9 col-form-label"
                    >
                      <span _ngcontent-ng-c635366232="">0</span>
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-md-12">
                  <div _ngcontent-ng-c635366232="" className="view-rolling row">
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-2 col-3 col-form-label"
                    >
                      Bookmaker
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="col-md-8 col-9 col-form-label"
                    >
                      <span _ngcontent-ng-c635366232="">0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c635366232=""
          id="UpdateCommissionModal"
          tabIndex={-1}
          aria-labelledby="yourModalLabel"
          aria-hidden="true"
          className="modal fade"
        >
          <div _ngcontent-ng-c635366232="" className="modal-dialog">
            <div _ngcontent-ng-c635366232="" className="modal-content">
              <div
                _ngcontent-ng-c635366232=""
                className="modal-header bg-primary"
              >
                <h5 _ngcontent-ng-c635366232="" className="modal-title">
                  Update Commission{" "}
                </h5>
                <button
                  _ngcontent-ng-c635366232=""
                  type="button"
                  className="close"
                >
                  <span
                    _ngcontent-ng-c635366232=""
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-body row">
                <div _ngcontent-ng-c635366232="" className="col-12">
                  <div _ngcontent-ng-c635366232="" className="form-group">
                    <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                      Commission{" "}
                      <span
                        _ngcontent-ng-c635366232=""
                        className="asterisk_input"
                      />
                    </div>
                    <input
                      _ngcontent-ng-c635366232=""
                      name="commission"
                      placeholder="Commission"
                      required=""
                      type="text"
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          commissionInCricket: e.target.value,
                        })
                      }
                      defaultValue={user?.commissionInCricketOdds}
                      value={payload?.commissionInCricket}
                      className="form-control ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c635366232="" className="col-12">
                  <div _ngcontent-ng-c635366232="" className="form-group">
                    <div _ngcontent-ng-c635366232="" htmlFor="meta_keywords">
                      Your Password{" "}
                      <span
                        _ngcontent-ng-c635366232=""
                        className="asterisk_input"
                      />
                    </div>
                    <div
                      _ngcontent-ng-c635366232=""
                      className="d-flex align-items-center position-relative"
                    >
                      <input
                        _ngcontent-ng-c635366232=""
                        name="masterPassword"
                        placeholder="Your Password.."
                        required=""
                        value={payload?.transactionPassword}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            transactionPassword: e.target.value,
                          })
                        }
                        className="form-control ng-untouched ng-pristine ng-invalid"
                        type="password"
                      />
                      <a _ngcontent-ng-c635366232="" className="eyebtn">
                        <i _ngcontent-ng-c635366232="" className="far fa-eye" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c635366232="" className="modal-footer">
                <button
                  _ngcontent-ng-c635366232=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  onClick={changeGlobalSettings}
                  className="btn btn-primary"
                  disabled=""
                >
                  Yes
                </button>
                <button
                  _ngcontent-ng-c635366232=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  className="btn btn-default"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </app-profile>
    ),
    "bet-history": <BetHistory state={state} userName={state?.username} />,
    "profit-loss": (
      <ProfitLossUserWise
        state={{
          ...state,
          fromProfile: true,
        }}
        userName={state?.username}
      />
    ),
    "account-statement": (
      <AccountStatement state={state} userName={state?.username} />
    ),
    "activity-log": <ActivityLog state={state} userName={state?.username} />,
  };

  return (
    <div _ngcontent-ng-c3796931036="" className="main">
      <div _ngcontent-ng-c3796931036="" className="container-fluid">
        <router-outlet _ngcontent-ng-c3796931036="" />
        <app-my-account-layout
          _nghost-ng-c2958549179=""
          className="ng-star-inserted"
        >
          <div _ngcontent-ng-c2958549179="" className="row ms-2 me-2">
            <div
              _ngcontent-ng-c2958549179=""
              className="col-md-3 col-sm-4 paddingmobile paddingDektop"
            >
              <div
                _ngcontent-ng-c2958549179=""
                className="card card-accent-primary"
              >
                <div _ngcontent-ng-c2958549179="" className="game-heading">
                  <span
                    _ngcontent-ng-c2958549179=""
                    className="card-header-title"
                  >
                    {" "}
                    My Account
                  </span>
                </div>
                <div
                  _ngcontent-ng-c2958549179=""
                  className="card-body account-detail-body p-0"
                >
                  <div _ngcontent-ng-c2958549179="" className="sidebar-account">
                    <a
                      _ngcontent-ng-c2958549179=""
                      routerlinkactive="active"
                      className={
                        location.pathname.includes("profile") && "active"
                      }
                      onClick={() => navigate("/my-account/profile")}
                    >
                      My Profile
                    </a>
                    {fromPreviousPage &&
                      fromPreviousPage.username != userData?.username && (
                        <a
                          _ngcontent-ng-c2958549179=""
                          routerlinkactive="active"
                          className={
                            location.pathname.includes("bet-history") &&
                            "active"
                          }
                          onClick={() =>
                            navigate("/my-account/bet-history", {
                              state: state,
                            })
                          }
                        >
                          Bet History
                        </a>
                      )}
                    {/**/}
                    {state?.userType == "CLIENT" && (
                      <a
                        _ngcontent-ng-c2958549179=""
                        routerlinkactive="active"
                        onClick={() =>
                          navigate("/my-account/profit-loss", { state: state })
                        }
                        className={
                          location.pathname.includes("profit-loss") && "active"
                        }
                      >
                        Profit &amp; Loss
                      </a>
                    )}
                    {/**/}
                    <a
                      _ngcontent-ng-c2958549179=""
                      routerlinkactive="active"
                      className={
                        location.pathname.includes("account-statement") &&
                        "active"
                      }
                      onClick={() =>
                        navigate("/my-account/account-statement", {
                          state: state,
                        })
                      }
                    >
                      Account Statement
                    </a>

                    <a
                      _ngcontent-ng-c2958549179=""
                      routerlinkactive="active"
                      className={
                        location.pathname.includes("activity-log") && "active"
                      }
                      onClick={() =>
                        navigate("/my-account/activity-log", { state: state })
                      }
                    >
                      Activity Log
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c2958549179=""
              className="col-md-9 col-sm-12 col-12 paddingmobile paddingDektop"
            >
              <router-outlet _ngcontent-ng-c2958549179="" />
              {pageMap[page]}
              {/**/}
            </div>
          </div>
        </app-my-account-layout>
        {/**/}
      </div>
    </div>
  );
}
