import { Box, FormLabel, Input, Select } from "@chakra-ui/react";
import React from "react";

export default function CustomSelect({ label, ...rest }) {
  return (
    <Box>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Select size="sm" {...rest}>
        {rest?.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
}
