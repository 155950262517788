import { Box, Button, Grid, GridItem, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import "./game.css";
import "./all.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import BetsLock from "./components/BetsLock";
import ViewMoreBet from "./components/ViewMoreBet";
import moment from "moment/moment";
import BookMakersBook from "./components/BookMakers";
import {
  betAndFancyLocksTennisAPI,
  getAdminPlExposureTennisAPI,
  getBetActiveDeactiveTennisAPI,
  getClientBetsTennisAPI,
  getTennisEventDetailsAPI,
  getTennisOddsByMatchIdAPI,
} from "../../service/soccer&tennis";
import { useCallback } from "react";
import { getLiveStreamingUrlAPI } from "../../service/AuthService";
export default function Tennis() {
  const [gameData, setGameData] = useState({
    gameName: "Cricket",
    gameType: "Match",
    time: "Jan 02,2023 10:00 AM",
  });
  const toast = useToast();
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [isBetsLockModalOpen, setIsBetsLockModalOpen] = useState(false);
  const [isBookMakerOpen, setIsBookMakerOpen] = useState(false);
  const [isViewMoreBetModalOpen, setIsViewMoreBetModalOpen] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [isLiveTvOpen, setIsLiveTvOpen] = useState(false);
  const [gameSettings_, setGameSettings_] = useState({});
  const [channelId, setChannelId] = useState(null);
  let items = location.pathname.split("/");
  var gameType = "tennis";
  var eventId = items[items.length - 1];
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const getGameEventDetails = useCallback(async () => {
    const { response } = await getTennisEventDetailsAPI(eventId);
    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  useEffect(() => {
    getGameEventDetails();
    setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);
  const getSoccerPlExposure = async () => {
    const { response } = await getAdminPlExposureTennisAPI(eventId);
    if (response) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_) => {
    const { response } = await getClientBetsTennisAPI(
      eventId,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveTennisAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  useEffect(() => {
    if (eventId) {
      getSoccerPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getSoccerPlExposure();
        getClientBets();
        getBetActiveDeactive();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId, betStatus]);

  const betAndFancyLocks = async (name, lock) => {
    let payload = {};
    if (name == "betlock") {
      payload.isBetLockedAll = lock;
    } else {
      payload.isFancyLockedALl = lock;
    }
    var response_;

    payload.gameId = eventId;
    var { response } = await betAndFancyLocksTennisAPI(payload);
    response_ = response;

    if (response_) {
      toast({
        title: "Success",
        description: response_?.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      setMatchData(response_);
      getBetActiveDeactive();
    }
  };
  const getExposure = (match, marketId, marketName = "FANCY") => {
    let exposure_;
    if (marketName == "FANCY") {
      exposure_ = gameSettings?.plExposureResponseDTOs?.filter(
        (item) => item.marketIdExternal == match.marketId
      );
      if (exposure_ && exposure_.length > 0) return exposure_[0];
      else return null;
    } else {
      gameSettings?.plExposureResponseDTOs?.map((item) =>
        item?.runnerPlExposureDTOS?.map((event) => {
          if (event?.selectionId == match?.selectionId) {
            exposure_ = event;
          }
        })
      );
    }
    return exposure_;
  };
  const getTennisOdds = async () => {
    const { response } = await getTennisOddsByMatchIdAPI(eventId);
    if (response) {
      setMatchOdds(response?.matchOddsResponseDTO[0]);
    }
  };
  useEffect(() => {
    if (eventId) {
      getTennisOdds();
      let interval = setInterval(() => {
        getTennisOdds();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [eventId]);
  const getCombinedExposureFromAll = () => {
    let uniqueRunnerName = [];
    let combinedRunnerObject = {};
    matchOdds?.oddDetailsDTOS?.map((item) => {
      if (!uniqueRunnerName.includes(item.runnerName)) {
        uniqueRunnerName.push(item.runnerName);
      }
    });

    uniqueRunnerName.map((item) => {
      let exposure = 0;

      matchData[0]?.runnerPlExposureDTOS?.map((match) => {
        if (match.selectionName == item) {
          exposure = exposure + match.exposure;
        }
      });
      combinedRunnerObject[item] = exposure;
    });

    return combinedRunnerObject;
  };
  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);
  return (
    <div class="col-md-12 main-container">
      {isBookMakerOpen && (
        <BookMakersBook
          type={isBookMakerOpen}
          eventId={eventId}
          isOpen={isBookMakerOpen}
          onClose={() => setIsBookMakerOpen(false)}
          gameType={gameType}
        />
      )}

      {/* <Books
        eventId={eventId}
        gameType={gameType}
        onClose={() => setIsBookModalOpen(false)}
        isOpen={isBooksModalOpen}
      /> */}
      {isBetsLockModalOpen && (
        <BetsLock
          eventId={eventId}
          gameType={gameType}
          onClose={() => setIsBetsLockModalOpen(false)}
          isOpen={isBetsLockModalOpen}
        />
      )}
      <ViewMoreBet
        eventId={eventId}
        gameType={gameType}
        onClose={() => setIsViewMoreBetModalOpen(false)}
        isOpen={isViewMoreBetModalOpen}
        data={clientBets}
        getClientBets={getClientBets}
      />
      <div class="listing-grid">
        <div class="table-top-buttons row">
          <div class="col-md-6"></div>
          <div class="col-md-6 float-right text-right">
            <form>
              <ul class="d-inline-block">
                <li class="form-group active-button m-l-20 d-inline-block BetActives">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="bet_lock"
                  >
                    Bet Lock
                  </a>
                  <ul class="sub-button">
                    <li>
                      <a
                        id="btn-bet_active_all"
                        data-status="0"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          betAndFancyLocks("betlock", !userBetStatus)
                        }
                      >
                        {userBetStatus ? "Deactive " : "All Active"}
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        id="btn-modal_userwisebetsttus"
                        onClick={() =>
                          setIsBetsLockModalOpen("betlockuserwise")
                        }
                      >
                        Userwise
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="form-group active-button m-l-20 d-inline-block FancyActive">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="btn-fancy_bet_lock"
                  >
                    Fancy Lock
                  </a>
                  <ul class="sub-button">
                    <li>
                      <a
                        id="btn-fancy_bet_active_all"
                        data-status="0"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          betAndFancyLocks("fancylock", !fancyBetStatus)
                        }
                      >
                        {fancyBetStatus ? "Deactive " : "All Active"}
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        id="btn-modal_userwisefencybetsttus"
                        onClick={() =>
                          setIsBetsLockModalOpen("fancylockuserwise")
                        }
                      >
                        Userwise
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="form-group active-button m-l-20 d-inline-block">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="modal-btn_userbook"
                    onClick={() => setIsBookMakerOpen("user")}
                  >
                    User Book
                  </a>
                </li>
                <li class="form-group active-button m-l-20 d-inline-block">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="modal-btn_userbookmaker"
                    onClick={() => setIsBookMakerOpen("book")}
                  >
                    Bookmarkers Book
                  </a>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div class="coupon-card row">
          <div class="col-md-8 main-content">
            {matchDetails?.eventId && (
              <iframe
                style={{
                  width: "100%",
                }}
                src={`https://animscore.proexch.in/#/score1/${matchDetails?.eventId}`}
              />
            )}
            <div class="game-heading m-b-5">
              <span
                class="card-header-title event_name_heading"
                id="spn_event_title"
                eventid={matchDetails?.eventId}
                marketid={matchDetails?.marketId}
                event_name={matchDetails?.eventName}
              >
                {Object.keys(getCombinedExposureFromAll()).map((item) => (
                  <span>
                    {item} : {getCombinedExposureFromAll()[item]}{" "}
                  </span>
                ))}
              </span>
            </div>
            <div class="game-heading m-b-5">
              <span
                class="card-header-title event_name_heading"
                id="spn_event_title"
                eventid={matchDetails?.eventId}
                marketid={matchDetails?.marketId}
                event_name={matchDetails?.eventName}
              >
                {matchDetails?.eventName} -&gt; MATCH_ODDS -&gt;
                {moment(matchDetails?.eventDate + "Z")
                  .utc()
                  .format("DD-MM-YYYY HH:mm A")}
              </span>
            </div>
            <div class="card-content">
              <div id="scoreboard-box"></div>
              <div id="match_odds_all_full_markets">
                <div
                  id="div_gamedata"
                  class="table-responsive m-b-10 main-market"
                >
                  <table
                    class="table coupon-table table table-bordered m-t-10 "
                    border="0"
                  >
                    <thead>
                      <tr>
                        <th>Match Odds</th> <th class="box-w1"></th>
                        <th class="box-w1"></th>
                        <th class="back box-w1">Back</th>
                        <th class="lay box-w1">lay</th> <th class="box-w1"></th>
                        <th class="box-w1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchOdds?.oddDetailsDTOS?.map((game) => (
                        <tr
                          class={`bet-info sec-4297012 ${game.status}`}
                          data-title={game.status}
                          id="fullSelection_890529_MATCH_ODDS"
                          eventtype="4"
                          selectionid={game.selectionId}
                          eventname={game.runnerName}
                          status={game.status}
                        >
                          <td>
                            <span class="team-name">{game.runnerName}</span>
                            <p class="box-w4">
                              <span
                                class="float-left profit live_match_points"
                                id="live_match_points_890529_MATCH_ODDS"
                                style={{ color: "rgb(0, 0, 0)" }}
                              >
                                {
                                  getExposure(game, matchOdds, "MATCH_ODDS")
                                    ?.exposure
                                }
                              </span>
                            </p>
                          </td>
                          <td class="box-w1 back2">
                            <button
                              id="back_3_890529_MATCH_ODDS"
                              fullmarketodds="1.03"
                            >
                              <span class="odd d-block">{game?.back3}</span>
                              <span class="d-block">{game?.backSize3}</span>
                            </button>
                          </td>
                          <td class="box-w1 back1">
                            <button
                              id="back_2_890529_MATCH_ODDS"
                              fullmarketodds="1.04"
                            >
                              <span class="odd d-block">{game?.back2}</span>
                              <span class="d-block">{game?.backSize2}</span>
                            </button>
                          </td>
                          <td class="back box-w1">
                            <button
                              class="back"
                              id="back_1_890529_MATCH_ODDS"
                              fullmarketodds="1.05"
                            >
                              <span class="odd d-block">{game?.back1}</span>
                              <span class="d-block">{game?.backSize1}</span>
                            </button>
                          </td>
                          <td class="lay box-w1">
                            <button
                              class="lay"
                              id="lay_1_890529_MATCH_ODDS"
                              fullmarketodds="1.06"
                            >
                              <span class="odd d-block">{game?.lay1}</span>
                              <span class="d-block">{game?.laySize1}</span>
                            </button>
                          </td>
                          <td class="box-w1 lay1">
                            <button
                              id="lay_2_890529_MATCH_ODDS"
                              fullmarketodds="1.07"
                            >
                              <span class="odd d-block">{game?.lay2}</span>
                              <span class="d-block">{game?.laySize2}</span>
                            </button>
                          </td>
                          <td class="box-w1 lay2">
                            <button
                              id="lay_3_890529_MATCH_ODDS"
                              fullmarketodds="1.08"
                            >
                              <span class="odd d-block">{game?.lay3}</span>
                              <span class="d-block">{game?.laySize3}</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 sidebar-right" id="sidebar-right">
            <div class="card m-b-10">
              <div
                class="card-header"
                data-toggle="collapse"
                data-target=".video-tv"
                id="showtv"
                onClick={() => setIsLiveTvOpen(!isLiveTvOpen)}
              >
                <h6 class="card-title">
                  Live Match
                  <span class="float-right">
                    <i class="fa fa-tv"></i> live stream started
                  </span>
                </h6>
              </div>
              <div
                id="video-tv"
                class={`video-tv ${isLiveTvOpen ? "" : "collapse"} `}
              >
                <div
                  align="center"
                  class="tv-container"
                  style={{ marginTop: "2px", marginBottom: "5px" }}
                >
                  {channelId && (
                    <iframe
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      src={`https://video.proexch.in/tv/static?chid=${channelId}`}
                    />
                  )}
                </div>
              </div>
            </div>
            {/*  <div class="card m-b-10" id="scoreboard-main">
              <div class="card-header">
                <h6 class="card-title">Score Card</h6>
              </div>
              <div
                class="card-body scoreboard-detail collapse"
                id="scoreboard-box"
                style={{ display: "block" }}
              >
                <div class="scorecard m-b-5">
                  <div class="row">
                    <span class="team-name col-md-2">WAR</span>
                    <span class="score col-md-6"></span>
                    <span class="col-md-2 run-rate"></span>
                    <span class="col-md-2 run-rate"></span>
                  </div>
                  <div class="row m-t-10">
                    <span class="team-name col-md-2">CC</span>
                    <span class="score col-md-6">130-3 (30.4)</span>
                    <span class="col-md-2 run-rate">CRR 4.24</span>
                    <span class="col-md-2 run-rate"></span>
                  </div>
                  <div class="row">
                    <div class="col-md-6 m-t-10"></div>
                    <div class="col-md-6 ball-runs-container m-t-5">
                      <p class="text-right ball-by-ball">
                        <span class="ball-runs ">w</span>
                        <span class="ball-runs ">0</span>
                        <span class="ball-runs ">1</span>
                        <span class="ball-runs ">0</span>
                        <span class="ball-runs ">1</span>
                        <span class="ball-runs ">0</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="card m-b-10 my-bet">
              <div class="card-header">
                <ul class="nav nav-tabs d-inline-block" role="tablist ">
                  <li class="nav-item d-inline-block">
                    <a
                      class={`nav-link ${
                        betStatus === "MATCHED" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => setBetStatus("MATCHED")}
                    >
                      Matched
                    </a>
                  </li>
                  <li class="nav-item d-inline-block">
                    <a
                      class={`nav-link ${
                        betStatus === "UNMATCHED" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => setBetStatus("UNMATCHED")}
                    >
                      Unmatched
                    </a>
                  </li>
                </ul>
                <a
                  href="javascript:void(0)"
                  id="view_more_bets"
                  class="btn btn-back float-right"
                  onClick={() => setIsViewMoreBetModalOpen(true)}
                >
                  View More
                </a>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div id="matched-bet" class="tab-pane active">
                    <div class="table-responsive">
                      <table
                        id="matched"
                        class="table coupon-table table-bordered table-stripted"
                      >
                        <thead>
                          <tr>
                            <th style={{ minWidth: "50px" }}>UserName</th>
                            <th style={{ minWidth: "200px" }}>Nation</th>
                            <th style={{ minWidth: "50px" }}>Rate</th>
                            <th style={{ minWidth: "70px" }}>Amount</th>
                            <th>PlaceDate</th>
                            <th>MatchDate</th>
                            <th style={{ minWidth: "70px" }}>Gametype</th>
                          </tr>
                        </thead>
                        <tbody id="tbody-active_bets">
                          {clientBets?.length > 0 &&
                            clientBets?.map((bet) => (
                              <tr
                                style={{
                                  borderLeft: `5px solid ${
                                    bet?.back
                                      ? "rgba(114, 187, 239, 1)"
                                      : "rgba(249, 148, 186, 1)"
                                  } `,
                                  backgroundColor: `${
                                    bet?.back
                                      ? "rgba(114, 187, 239, 1)"
                                      : "rgba(249, 148, 186, 1)"
                                  } `,
                                }}
                                colspan="100%"
                              >
                                <td>{bet?.userName}</td>
                                <td>{bet?.runnerName}</td>
                                <td>{bet?.rate}</td>
                                <td>{bet?.amount}</td>
                                <td>
                                  {moment(bet?.placeDate + "Z")
                                    .utc()
                                    .local()
                                    .format("DD/MM/YY hh:mm:ss A")}
                                </td>
                                <td>
                                  {moment(bet?.placeDate).format("DD/MM/YY ")}
                                </td>
                                <td>{bet?.marketName}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="unmatched-bet" class="tab-pane">
                    <div class="table-responsive">
                      <table
                        id="unmatched"
                        class="table coupon-table table-bordered table-stripted"
                      >
                        <thead>
                          <tr>
                            <th>UserName</th>
                            <th style={{ minWidth: "200px" }}>Nation</th>
                            <th style={{ minWidth: "50px" }}>Rate</th>
                            <th style={{ minWidth: "70px" }}>Amount</th>
                            <th>MatchDate</th>
                            <th>Gametype</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="100%" align="center">
                              No record found!...
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
