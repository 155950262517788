import { AXIOS_INSTANCE } from "..";
import { getToken, SOCCER_AND_TENNIS_SERVICE_URL } from "../../utils/constants";

export const getAllSoccerMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/all-matches/list`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllTennisMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/all-matches/list`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminUserBookSoccerAPI = async (soccerId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/user-book?eventId=${soccerId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminUserBookTennisAPI = async (tennisId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/user-book?eventId=${tennisId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminBookMakerTennisAPI = async (tennisId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/bookmaker-book?eventId=${tennisId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminBookMakerSoccerAPI = async (soccerId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/bookmaker-book?eventId=${soccerId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAdminPlExposureSoccerAPI = async (soccerId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/admin-pl?eventId=${soccerId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminPlExposureTennisAPI = async (tennisId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/admin-pl?eventId=${tennisId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getClientBetsTennisAPI = async (tennisId, betStatus) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/client-bets/${tennisId}`,
      {
        params: {
          eventId: tennisId,
          betStatus,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getClientBetsSoccerAPI = async (soccerId, betStatus) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/client-bets/${soccerId}`,
      {
        params: {
          eventId: soccerId,
          betStatus,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMatchDetailsSoccerAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/match-details`,
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchDetailsTennisAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/match-details`,
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const betAndFancyLocksSoccerAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/update-status`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getBetLockUsersSoccerAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/bet-lock-users`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const betAndFancyLocksTennisAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/update-status`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getBetLockUsersTennisAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/bet-lock-users`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const getBetActiveDeactiveSoccerAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/bet-active-deactive`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetActiveDeactiveTennisAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/admin/bet-active-deactive`,
      {
        params: {
          cricketId,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserBookSoccerAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/admin/user-book?eventId=${eventId}`,
      {
        params: {
          eventId,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSoccerOddsByMatchIdAPI = async (matchId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/odds/${matchId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getTennisOddsByMatchIdAPI = async (matchId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/odds/${matchId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSoccerEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/soccer/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getTennisEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};


