import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { accountLogAPI, searchUserAPI } from "../../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";
import ClientLegder from "./Modal/ClientLegder";
export default function AccountStatement({ userName: { username }, state }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("ALL");
  const [userName, setUserName] = useState(null);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [headerAccountLedger, setHeaderAccountLedger] = useState("");
  const [search, setSearch] = useState("");
  const [selectedLogIs, setSelectedLogIs] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  const getAccountStatement = async () => {
    let payload = {
      pageNumber: page,
      pageSize: limit,
      type: type,
      startDate: new Date(fromDate),
      endDate: new Date(toDate),
      search,
    };
    if (userName != null && userName?.value?.length > 0) {
      payload["userName"] = userName?.value;
    }
    if (state?.username) {
      payload["userName"] = state?.username;
    }
    const { response, message, code } = await accountLogAPI(payload);
    if (code == 200) {
      setData(response);
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  useEffect(() => {
    getAccountStatement();
  }, [page, limit, type, userName, fromDate, toDate, search]);

  return (
    <app-account-statement
      _nghost-ng-c1498867423=""
      className="ng-star-inserted"
    >
      {selectedLogIs && (
        <ClientLegder
          setAccountLogId={setSelectedLogIs}
          accountLogId={selectedLogIs.accountLogId}
        />
      )}
      <div _ngcontent-ng-c1498867423="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c1498867423="" className="row">
          <div _ngcontent-ng-c1498867423="" className="col-sm-12 col-md-12">
            <div _ngcontent-ng-c1498867423="" className="card card-report">
              <div _ngcontent-ng-c1498867423="" className="card-body row p-0">
                <div
                  _ngcontent-ng-c1498867423=""
                  className="col-lg-3 col-md-4 col-sm-8"
                >
                  <div _ngcontent-ng-c1498867423="" className="form-group">
                    <label _ngcontent-ng-c1498867423="" htmlFor="datasource">
                      Data Source
                    </label>
                    <select
                      _ngcontent-ng-c1498867423=""
                      id="datasource"
                      className="form-control form-select ng-untouched ng-pristine ng-valid"
                    >
                      <option _ngcontent-ng-c1498867423="" disabled="" value="">
                        Data Source
                      </option>
                      <option _ngcontent-ng-c1498867423="" value="LIVE">
                        LIVE DATA
                      </option>
                      <option _ngcontent-ng-c1498867423="" value="BACKUP">
                        BACKUP DATA
                      </option>
                      <option _ngcontent-ng-c1498867423="" value="OLD">
                        OLD DATA
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1498867423=""
                  className="col-lg-3 col-md-4 col-sm-12"
                >
                  <div _ngcontent-ng-c1498867423="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c1498867423=""
                      htmlFor="startDate"
                      className="mb-2"
                    >
                      From
                    </div>
                    <div _ngcontent-ng-c1498867423="" className="input-group">
                      <input
                        _ngcontent-ng-c1498867423=""
                        placeholder="Select Date"
                        id="startDate"
                        name="mydate"
                        type="date"
                        value={moment(fromDate).format("YYYY-MM-DD")}
                        onChange={(e) => setFromDate(e.target.value)}
                        readOnly=""
                        autoComplete="off"
                        className="form-control dateClass ng-valid ng-touched ng-dirty"
                        style={{ padding: "0px !important" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1498867423=""
                  className="col-lg-3 col-md-4 col-sm-12"
                >
                  <div _ngcontent-ng-c1498867423="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c1498867423=""
                      htmlFor="endDate"
                      className="mb-2 marTo"
                    >
                      To
                    </div>
                    <input
                      _ngcontent-ng-c1498867423=""
                      placeholder="Select Date"
                      id="startDate"
                      type="date"
                      name="mydate"
                      value={moment(toDate).format("YYYY-MM-DD")}
                      onChange={(e) => setToDate(e.target.value)}
                      readOnly=""
                      autoComplete="off"
                      className="form-control dateClass ng-valid ng-touched ng-dirty"
                      style={{ padding: "0px !important" }}
                    ></input>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1498867423=""
                  className="col-lg-3 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c1498867423="" className="form-group">
                    <div
                      _ngcontent-ng-c1498867423=""
                      htmlFor="statementbtn"
                      className="mobile-d-none"
                      style={{ width: "100%" }}
                    >
                      &nbsp;
                    </div>
                    <button
                      _ngcontent-ng-c1498867423=""
                      id="statementbtn"
                      type="button"
                      onClick={getAccountStatement}
                      className="btn btn-primary btnMrgn"
                    >
                      <strong _ngcontent-ng-c1498867423="">
                        Get Statement
                      </strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c1498867423="" className="row">
          <div _ngcontent-ng-c1498867423="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c1498867423=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c1498867423=""
                className="card-header account-detail-head"
              >
                {" "}
                Account Statement{" "}
              </div>
              <div
                _ngcontent-ng-c1498867423=""
                className="card-body account-stat-body"
              >
                <div
                  _ngcontent-ng-c1498867423=""
                  className="table-responsive table table-striped"
                >
                  <div
                    id="DataTables_Table_29_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_29_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="DataTables_Table_29_length"
                          aria-controls="DataTables_Table_29"
                          className=""
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="DataTables_Table_29_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder=""
                          aria-controls="DataTables_Table_29"
                        />
                      </label>
                    </div>
                    <table
                      _ngcontent-ng-c1498867423=""
                      datatable=""
                      role="table"
                      aria-busy="false"
                      aria-colcount={6}
                      className="table table-bordered table-striped dataTable no-footer"
                      id="DataTables_Table_29"
                      aria-describedby="DataTables_Table_29_info"
                    >
                      <thead _ngcontent-ng-c1498867423="" role="rowgroup">
                        <tr _ngcontent-ng-c1498867423="" role="row">
                          <th
                            _ngcontent-ng-c1498867423=""
                            className="sorting sorting_desc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_29"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date/Time: activate to sort column ascending"
                            aria-sort="descending"
                          >
                            Date/Time
                          </th>
                          <th
                            _ngcontent-ng-c1498867423=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_29"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Deposit: activate to sort column ascending"
                          >
                            Deposit
                          </th>
                          <th
                            _ngcontent-ng-c1498867423=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_29"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Withdraw: activate to sort column ascending"
                          >
                            Withdraw
                          </th>
                          <th
                            _ngcontent-ng-c1498867423=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_29"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Balance: activate to sort column ascending"
                          >
                            Balance
                          </th>
                          <th
                            _ngcontent-ng-c1498867423=""
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Remark"
                          >
                            Remark
                          </th>
                          <th
                            _ngcontent-ng-c1498867423=""
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="from//To"
                          >
                            from//To
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((log, index) => (
                          <tr
                            _ngcontent-ng-c1498867423=""
                            role="row"
                            className="odd"
                            key={index}
                          >
                            <td
                              _ngcontent-ng-c1498867423=""
                              className="sorting_1"
                            >
                              {moment(log.createdAt + "Z").format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </td>
                            <td _ngcontent-ng-c1498867423="">
                              {log.creditInfo}
                            </td>
                            <td _ngcontent-ng-c1498867423="">
                              {log.debitInfo}
                            </td>
                            <td _ngcontent-ng-c1498867423="">
                              {log.currentBalance}
                            </td>
                            <td
                              onClick={() => {
                                setSelectedLogIs(log);
                                setIsModalOpen(true);
                              }}
                              style={{
                                textDecoration: "underline",
                              }}
                              _ngcontent-ng-c1498867423=""
                            >
                              {log.comment}
                            </td>
                            <td _ngcontent-ng-c1498867423="">{log.fromTo}</td>
                          </tr>
                        ))}
                        {data.length == 0 && (
                          <tr className="odd">
                            <td
                              valign="top"
                              colSpan={7}
                              className="dataTables_empty"
                            >
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {/**/}
                      <tbody>
                        <tr
                          _ngcontent-ng-c1498867423=""
                          className="odd ng-star-inserted"
                        >
                          <td
                            _ngcontent-ng-c1498867423=""
                            colSpan={10}
                            className="no-data-available"
                          >
                            No data!
                          </td>
                        </tr>
                        {/**/}
                      </tbody>
                    </table>
                    <div
                      className="dataTables_info"
                      id="DataTables_Table_29_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 0 to 0 of 0 entries
                    </div>
                    <div
                      className="dataTables_paginate paging_full_numbers"
                      id="DataTables_Table_29_paginate"
                    >
                      <a
                        className="paginate_button first disabled"
                        aria-controls="DataTables_Table_29"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="first"
                        tabIndex={-1}
                        id="DataTables_Table_29_first"
                      >
                        First
                      </a>
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="DataTables_Table_29"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabIndex={-1}
                        id="DataTables_Table_29_previous"
                      >
                        Previous
                      </a>
                      <span />
                      <a
                        className="paginate_button next disabled"
                        aria-controls="DataTables_Table_29"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="next"
                        tabIndex={-1}
                        id="DataTables_Table_29_next"
                      >
                        Next
                      </a>
                      <a
                        className="paginate_button last disabled"
                        aria-controls="DataTables_Table_29"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="last"
                        tabIndex={-1}
                        id="DataTables_Table_29_last"
                      >
                        Last
                      </a>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-account-statement>
  );
}
